import s from './FeaturedProducts.module.css';
import SectionTitle from '../SectionTitle';
// eslint-disable-next-line max-len
import {AspectRatio, Box, Card, CardBody, Heading, SimpleGrid, Stack, VStack, Text} from '@chakra-ui/react';
import Image from 'next/image';
import Link from 'next/link';


const FeaturedProducts = ({products}) => {
  return (
    <div className={s.container}>
      <div className={s.title}>
        <SectionTitle
          title={'Online Shop'}
          subtitle={'Check our products!'} />
      </div>
      <SimpleGrid
        className={s.wrapper}
        margin={{base: '37px 0', sm: '37px 25%'}}
        minChildWidth={'320px'}
        spacingX={20}
        spacingY={10}>
        {products.map((product) => {
          const price = product.hasVariants ?
              product.variants[0].price :
              product.price;
          return (<Box
            maxW={'320px'}
            width={'100%'}
            margin={'auto'}
            key={product.productId}>
            <Card>
              {product.images?.length > 0 &&
                <AspectRatio
                  ratio={4 / 3}
                  position={'relative'}>
                  <Image
                    src={product.images[0]}
                    alt={'image'}
                    fill={true}
                    className={s.image}/>
                </AspectRatio>}
              <Stack mt='6' spacing='3'>
                <CardBody>
                  <VStack
                    alignItems={'start'}>
                    <Heading size={'md'}>{product.productName}</Heading>
                    <Text color='blue.600' fontSize='2xl'>
                        ${price / 100}
                    </Text>
                  </VStack>
                </CardBody>
              </Stack>
            </Card>
          </Box>);
        })}
      </SimpleGrid>
      <Link href={'/store'}>
        <Box
          textAlign={'center'}>
          <button className={'custom__button'}>View All Products</button>
        </Box>
      </Link>
    </div>
  );
};

export default FeaturedProducts;
