import {
  Featured,
  Header,
  Locations,
} from '../container';
import axios from 'axios';
import {parseLocationInfo} from '../helpers/utils';
import {reportError} from '../helpers/logging';
import {isEmpty} from 'lodash';
import FeaturedProducts from '../components/FeaturedProducts';

/**
 * Website entry
 * @return {JSX.Element}
 * @constructor
 */
function HomePage({featuredItems, newLocations, onlineProducts}) {
  const parsedNewLocations = newLocations.map((newLocation) => {
    const parsedInfo = parseLocationInfo(newLocation);
    return {
      ...newLocation,
      ...parsedInfo,
    };
  });
  return (
    <>
      <Header />
      <FeaturedProducts
        products={onlineProducts} />
      {
        !isEmpty(featuredItems) &&
            <Featured
              items={featuredItems}
              menuUrl={`/menu`}/>
      }
      {
        !isEmpty(parsedNewLocations) &&
            <Locations newLocations={parsedNewLocations}/>
      }
    </>
  );
}


/**
 * Get API responses
 * @return {Promise<{}>}
 */
export async function getStaticProps() {
  let featuredItems = [];
  let newLocations = [];
  let onlineProducts = [];

  try {
    const API_V1 = process.env.NEXT_PUBLIC_API_V1;
    const featuredItemsResp = await axios(API_V1 +
        'menu/featured');
    featuredItems = featuredItemsResp.data;
    const newLocationsResp = await axios(API_V1 + 'locations/recent-opened');
    newLocations = newLocationsResp.data;
    const onlineProductsResp = await axios(API_V1 + 'products');
    onlineProducts = onlineProductsResp.data;
  } catch (e) {
    console.error('Error: ' + e);
    reportError(JSON.stringify(e, Object.getOwnPropertyNames(e)));
  }

  return {
    props: {
      featuredItems,
      newLocations,
      onlineProducts,
    },
    revalidate: parseInt(process.env.REVALIDATE_PERIOD),
  };
}

export default HomePage;
